;
Array.prototype.find = Array.prototype.find || function(predicate) {
    if (this === null) {
        throw new TypeError('Array.prototype.find called on null or undefined');
    } else if (typeof predicate !== 'function') {
        throw new TypeError('callback must be a function');
    }
    var list = Object(this);
    var thisArg = arguments[1];
    for(var i = 0; i < list.length; i++) {
        var element = list[i];
        if(predicate.apply(thisArg,[element, i, list])) {
            return this[i];
        }
    }
}
;
if (typeof Object.assign != 'function') {
    Object.assign = function(target) {
      'use strict';
      if (target == null) {
        throw new TypeError('Cannot convert undefined or null to object');
      }
  
      target = Object(target);
      for (var index = 1; index < arguments.length; index++) {
        var source = arguments[index];
        if (source != null) {
          for (var key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
              target[key] = source[key];
            }
          }
        }
      }
      return target;
    };
  }
;
(function($) {
    
        function EqualRoot(elem, config) {
            var _ = this;
            
            var defaultConfig = {
                targetSelector: '[data-equalize="child"]'
            };
    
            _.config = Object.apply({}, [defaultConfig, config]);
            _.$elem = $(elem);      
    
    
            $(window).on('resize', function() {
                _.equalize();
            });

            _.equalize();
        }
    
        EqualRoot.prototype.equalize = function() {
            var _ = this;
            var $children = _.$elem.find(_.config.targetSelector);
    
            var max = 0;
            // clear height otherwise height will be what was last set with inline style
            $children.height('');
            $children.each(function() {
                max = Math.max(max, $(this).outerHeight());
            });
            $children.height(max);
        };
    
        $.fn.equalHeight = function() {
            var _ = this,
                opt = arguments[0] || {};
            for (var i = 0; i < _.length; i++) {    
                if (_[i].equalHeight === undefined) {
                    _[i].equalHeight = new EqualRoot(_[i], opt);
                }
            }
        };
    })(jQuery);
;
var app = app || {};

(function() {

    app.stringhelper = function() {}
    
    app.stringhelper.isNullOrWhitespace = function(s) {
        return s === null || s === undefined || /^\s*$/.test(s);
    }
    
    /**
     * Gets a cookie value by name
     * @param {string} cookieName The name of the cookie value to retrieve
     */
    app.getCookie = function(cookieName) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + cookieName + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    }

    /**
     * Sets an session cookie
     * @param {string} cookieName The name of the cookie to set
     * @param {*} value The value contained in the set cookie
     * @param {number} expiryDays The days from now to expire the cookie, leave blank to keep indefinately
     */
    app.setCookie = function(cookieName, value, expiryDays) {
        if(!cookieName) 
            throw "No cookie name supplied";
        var expires = "";
        if(expiryDays) {
            var date = new Date();
            date.setTime(date.getTime() + (expiryDays*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }

        document.cookie = '' + cookieName +  '=' + encodeURIComponent(value || '') + expires + ";path=/";
    }

    /**
     * Scrolls the user to top of current page
     * * @param {number} time The animation duration in ms
     */
    app.scrollTop = function(time) {
        // apply a sensible default to time
        time = (!time || time <= 0) ? 800 : time;

        // Dont scroll if already near top
        if($(window).scrollTop() < 20) {
            return;
        }

        $('html, body').animate({ scrollTop: 0}, time);
    };

    /**
     * Trims a string to nearest whole word
     * @param {string} s the string to trim
     * @param {number} length the length to start looking for nearest end of word and trim
     */
    app.trimNearestWord = function(s, length) {
        if(!length)
            return s;

        length = Math.max(0, length - 1);

        
        if(!s || s.length <= length)
            return s;

        for(var i = length; i < s.length; i++) {
            if(s[i] == ' ') {
                return s.substring(0, i);
            }
        }
        return s;
    }

    /**
     * 
     * @param {function} func the function to be executed after debounce
     * @param {number} delay the delay in ms between executing function
     */
    app.debounce = function(func, delay) {
        var id;
    
        return function () {
            var self = this;
            var args = arguments;
            clearTimeout(id);
    
            id = setTimeout(function () {
                func.apply(self, args);
            }, delay)
        }
    }

})();

;
/**
 * Uses SwiperJS http://idangero.us/swiper/api/
 */
(function(window, $) {

    function HeroCarousel(elem, config) {
        var _ = this;

        var defaults = {
            container: '.swiper-container',
            direction: 'horizontal',            
            centeredSlides: true,
            slidesPerView: 5,
            loop: true,
            loopedSlides: 10,
            breakpoints: {
                768: {
                    slidesPerView: 1
                },
                1200: {
                    slidesPerView: 2
                },
                1600: {
                    slidesPerView: 3
                },
                2048: {
                    slidesPerView: 4
                }
            }
        }
        _.config = Object.assign({}, defaults, config);
        _.elem = elem;
        _.$elem = $(elem);
                
    };

    HeroCarousel.prototype.initialize = function() {
        var _ = this;
        if(_.swiper !== undefined) {
            // Destroy swiper if previously initialized
            _.swiper.destroy(true, true);
        }
        _.swiper = new Swiper(_.elem, _.config);
    };

    $.fn.herocarousel = function() {
        var _ = this, 
            opts = arguments[0] || {};
        
        for (var i = 0; i < _.length; i++) {
            if(_[i].herocarousel === undefined)
               _[i].herocarousel = new HeroCarousel(_[i], opts).initialize();
        }
    };

})(window, jQuery);
;
$(function() {
    $('.hub-control').each(function(e) {
        var config = {
            focusClass: 'focus',
            hasValueClass: 'dirty'
        };
    
        var $el = $(this);
        
        var $input = $el.find('input');
    
        $input.on('focus focusin', function() {
            $el.addClass(config.focusClass);
        });
    
        $input.on('blur focusout', function() {
            $el.removeClass(config.focusClass);
        });
    
        $input.on('change keyup keydown', function() {
            var val = !app.stringhelper.isNullOrWhitespace($input.val());
            $el.toggleClass(config.hasValueClass, val);
        }).trigger('change');
    });
});

;
(function($) {

    function PageItem(pager, pageNo) {

        if(!pager) {
            throw "Cannot create PageItem, pager argument invalid"
        }
        this.pager = pager;
        this.pageNo = pageNo; 
    };

    /**
     * Handles the on click event for a PageItem
     * @param {MouseEvent} evt 
     */
    PageItem.prototype.onclick = function(evt) {
        this.pager.page(this.pageNo);
    };

    /**
     * Uses jQuery to create a new list item element
     */
    PageItem.prototype.render = function() {
        var self = this;

        var el = $('<li></li>');
        el.attr('page-' + this.pageNo);
        el.addClass('page page-no');
        el.addClass('page-' + this.pageNo);
        if(this.pageNo == this.pager.currentPage)
            el.addClass('active');

        el.on('click', function() { self.onclick.apply(self); });
        el.text(this.pageNo);
        return el;
    };

    /**
     * Pager - to facilitate pagination
     * @param {Object} elem The DOM element to create the pager
     */
    function Pager(elem, options) {

        var defaults = {
            pageNo: 0,
            totalItems: 0,
            pageSize: 0,
            noOfPages: 5,
            onpaged: function() {}
        };

        this.config = Object.assign({}, defaults, options);

        this.$el = $(elem);
        this.pages = [];

        this.currentPage = this.config.pageNo;        
        this.pageSize = this.config.pageSize;
        this.totalItems = this.config.totalItems;

        this.render();
    };

    /**
     * Creates and renders all child page items
     */
    Pager.prototype.renderPages = function() { 
        var totalPages = this.totalPages();

        // [01][02][03][04][05]
        //      ^
        // [16][17][18][19][20]
        //               ^
        
        
        var p = Math.max(1, Math.min(this.config.noOfPages, totalPages));
        var start = this.currentPage - Math.floor(p / 2);
        var end = this.currentPage + Math.floor(p / 2);

        if(end > totalPages) {
            end = totalPages;
            start = end - (p - 1);
        }

        if(start < 1) {
            start = 1;
            end = p;
        }
        
        this.pages = [];
        for(var i = start - 1; i < end;) {
            var item = new PageItem(this, ++i);
            this.pages.push(item.render());
        }

        return this.pages;
    };

    /**
     * Computes total pages
     */
    Pager.prototype.totalPages = function() {
        return Math.ceil(this.totalItems / this.pageSize);
    };

    /**
     * Pages to the requested page
     * @param {number} pageNo The page to request
     */
    Pager.prototype.page = function(pageNo, force) {
        if(pageNo === undefined) {
            return this.currentPage;
        }

        if(this.currentPage == pageNo && !force)
            return;

        this.setPage(pageNo);

        this.config.onpaged(this);        
    };

    /**
     * Sets the current page without paging
     * @param {number} pageNo The page number to set to
     */
    Pager.prototype.setPage = function(pageNo) {
        if(pageNo === undefined || pageNo < 1) {
            throw "Invalid page value";
        }

        if(pageNo < 1) {
            throw "Invalid page value, must be greater than 0";
        }
        this.currentPage = pageNo;

        this.render();
    };

    /**
     * Sets the total number of items and re-renders
     * @param {number} total the total number of items
     */
    Pager.prototype.setTotalItems = function(total) {
        this.totalItems = total;

        this.render();
    };

    /**
     * Pages to the last available page
     */
    Pager.prototype.lastPage = function() {
        this.page(this.totalPages());
    };

    /**
     * Pages to the first available page
     */
    Pager.prototype.firstPage = function() {
        this.page(1);
    };

    /**
     * Pages to the previous available page
     */
    Pager.prototype.previousPage = function() {
        var prev = this.currentPage - 1;
        if(prev <= 0)
            return;
        this.page(prev);
    };

    /**
     * Pages to the next available page
     */
    Pager.prototype.nextPage = function() {
        var next = this.currentPage + 1;
        if(next > this.totalPages())
            return;
        this.page(next);
    };

    /**
     * Renders this component
     */
    Pager.prototype.render = function() {
        var self = this;
        this.$el.empty();

        var totalPages = this.totalPages();
        if(totalPages <= 1)
            return;
        
        var $list = $('<ul></ul>');

        if(this.currentPage > 1) {
            var $first = $('<li class="page first-page"><<</li>');
            $first.on('click', function() { self.firstPage(); });

            var $prev = $('<li class="page prev-page"><</li>');
            $prev.on('click', function() { self.previousPage(); })

            $list.append($first);
            $list.append($prev);
        }
        
        $list.append(this.renderPages());

        if(this.currentPage < this.totalPages()) {
            var $next = $('<li class="page next-page">></li>');
            $next.on('click', function() { self.nextPage(); });

            var $last = $('<li class="page last-page">>></li>');
            $last.on('click', function() { self.lastPage(); })
            
            $list.append($next);
            $list.append($last);
        }

        this.$el.append($list);
    };

    /**
     * Adds the jQuery factory method
     */
    $.fn.pager = function( ) {
        var _ = this, 
            options = arguments[0];

        for(var i = 0; i < _.length; i++) {
            if(_[i].pager === undefined) {
                _[i].pager = new Pager(_[i], options);
            }
        };
        return _;
    };

})(jQuery);
;
var app = app || {};

/**
 * Register cookie notice dismissal event listener
 * sets cookie upon click and hides element
 */
(function () {
    var cookieName = 'cookie-policy';
    var cookieVal = '1';

    $('section.cookie-notice a').on('click', function () {
        app.setCookie(cookieName, cookieVal, 365);
        $('section.cookie-notice').animate({ bottom: -($('section.cookie-notice').height() + 50) });
    });
})();